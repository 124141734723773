import { useConnectModal } from '@rainbow-me/rainbowkit'
import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { Period } from '@dextoroprotocol/sdk/constants'
import { formatDollars, formatPercent } from '@dextoroprotocol/sdk/utils'
import {
	formatChartDate,
	formatChartTime,
	formatShortDateWithTime,
} from '@dextoroprotocol/sdk/utils'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts'
import styled, { useTheme } from 'styled-components'

import useIsL2 from 'hooks/useIsL2'
import bgImage from 'assets/png/currencies/chart-background.png'
import SmartWalletIcon from 'components/Icons/smartWalletIcon'
import HelpIcon from 'assets/svg/app/help.svg'
import Button from 'components/Button'
import { GridDivCenteredRow } from 'components/layout/grid'
import {
	DesktopLargeOnlyView,
	DesktopOnlyView,
	DesktopSmallOnlyView,
	MobileHiddenView,
	MobileOnlyView,
} from 'components/Media'
import { Body, NumericValue, Heading } from 'components/Text'
import { FlexDivRowCentered } from 'components/layout/flex'
import { Timeframe } from 'sections/dashboard/Timeframe'

import { selectFuturesType } from 'state/futures/common/selectors'
import {
	selectFuturesPortfolio,
	selectPortfolioChartData,
	selectSelectedPortfolioTimeframe,
	selectTotalUnrealizedPnl,
} from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'
import { selectIdleAccountMargin } from 'state/futures/smartMargin/selectors'
import { selectCurrentTheme } from 'state/preferences/selectors'
import Connector from 'containers/Connector'
import useLocalStorage from 'hooks/useLocalStorage'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'
import media from 'styles/media'

type PriceChartProps = {
	setHoverValue: (data: number | null) => void
	setHoverTitle: (data: string | null) => void
}
const CustomLegend = ({ value, color }: { value: string; color: string }) => {
	return (
		<FlexDiv>
			{/* {color === '#EF6868' ? <IconRed></IconRed> : <IconGreen color={color}></IconGreen>} */}
			<SmartWalletIcon color={color}></SmartWalletIcon>
			<LegendBody $color={color}>{value}</LegendBody>
		</FlexDiv>
	)
}
const ChartCTA: FC<{ mobile?: boolean; isL2?: boolean }> = ({ mobile, isL2 }) => {
	const { t } = useTranslation()
	const { openConnectModal } = useConnectModal()
	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}
	return (
		<CTAContainer isMobile={mobile}>
			<WelcomeHeading variant="h3">
				{t('dashboard.overview.portfolio-chart.welcome')}
			</WelcomeHeading>
			<Body color={'secondary'}>
				{isL2
					? t('dashboard.overview.portfolio-chart.hero')
					: t('dashboard.overview.portfolio-chart.hero-wrong-network')}
				<br />
				{isL2 && t('dashboard.overview.portfolio-chart.hero-para')}
			</Body>
			{isL2 && (
				<Button variant="flat" size="xsmall" onClick={openConnect}>
					{t('homepage.nav.trade-now')}
				</Button>
			)}
			{firstVisitModal && (
				<FirstSeenWalletConnectionModal
					onDismiss={closeFirstVisitModal}
					onConfirmed={visitModalConfirmed}
				></FirstSeenWalletConnectionModal>
			)}
		</CTAContainer>
	)
}

const PriceChart: FC<PriceChartProps> = ({ setHoverValue, setHoverTitle }) => {
	const theme = useTheme()
	const portfolioTimeframe = useAppSelector(selectSelectedPortfolioTimeframe)
	const accountType = useAppSelector(selectFuturesType)
	const portfolioChartData = useAppSelector(selectPortfolioChartData)

	const portfolioData = useMemo(
		() => portfolioChartData[accountType],
		[portfolioChartData, accountType]
	)

	const lineColor = useMemo(() => {
		const isNegative =
			portfolioData.length > 2
				? portfolioData[portfolioData.length - 1].total - portfolioData[0].total < 0
				: false
		return theme.colors.selectedTheme[isNegative ? 'red' : 'green']
	}, [portfolioData, theme])

	return (
		<Container width="100%" height="100%">
			<LineChart
				data={portfolioData}
				margin={{ top: 15, bottom: 0, left: 0, right: 0 }}
				onMouseLeave={() => {
					setHoverValue(null)
					setHoverTitle(null)
				}}
				onMouseMove={(payload) => {
					if (payload.activePayload && payload.activePayload.length > 0) {
						const newTotal = payload.activePayload[0].payload?.total

						const formattedDate = formatShortDateWithTime(
							payload.activePayload[0].payload?.timestamp
						)
						if (newTotal) {
							setHoverValue(newTotal)
							setHoverTitle(formattedDate)
						} else {
							setHoverValue(null)
							setHoverTitle(null)
						}
					} else {
						setHoverValue(null)
						setHoverTitle(null)
					}
				}}
			>
				<XAxis
					dataKey="timestamp"
					type="number"
					scale="time"
					minTickGap={75}
					tickFormatter={portfolioTimeframe === Period.ONE_WEEK ? formatChartTime : formatChartDate}
					domain={['dataMin', 'dataMax']}
					stroke="#6f6e84"
				/>
				<Tooltip content={<></>} />
				<Legend
					content={
						<CustomLegend
							value={accountType === 'cross_margin' ? 'Cross Margin' : 'Portfolio'}
							color={lineColor}
						></CustomLegend>
					}
					verticalAlign="top"
					align="left"
				/>
				<Line
					type="monotone"
					dataKey="total"
					stroke={lineColor}
					dot={false}
					isAnimationActive={false}
					strokeWidth={3}
				/>
			</LineChart>
		</Container>
	)
}

const Container = styled(ResponsiveContainer)`
	font-feature-settings: 'zero' 0;
`

const PortfolioChart: FC<any> = ({ POSITIONS_TABS }) => {
	const { t } = useTranslation()
	const currentTheme = useAppSelector(selectCurrentTheme)
	const { crossMargin: crossTotal, smartMargin: smartTotal } =
		useAppSelector(selectFuturesPortfolio)
	const accountType = useAppSelector(selectFuturesType)
	const { cross_margin: crossPortfolioData, smart_margin: smartPortfolioData } =
		useAppSelector(selectPortfolioChartData)

	const upnl = useAppSelector(selectTotalUnrealizedPnl)
	const smartAccountBalance = useAppSelector(selectIdleAccountMargin)

	const [hoverValue, setHoverValue] = useState<number | null>(null)
	const [hoverTitle, setHoverTitle] = useState<string | null>(null)

	const total = useMemo(
		() => (accountType === 'cross_margin' ? crossTotal : smartTotal),
		[accountType, crossTotal, smartTotal]
	)

	const portfolioData = useMemo(() => {
		return accountType === 'cross_margin' ? crossPortfolioData : smartPortfolioData
	}, [accountType, crossPortfolioData, smartPortfolioData])

	const changeValue = useMemo(() => {
		if (portfolioData.length < 2) {
			return {
				value: null,
				text: '',
			}
		} else {
			const value =
				(hoverValue ?? portfolioData[portfolioData.length - 1].total) - portfolioData[0].total
			const changeValue = portfolioData[0].total > 0 ? value / portfolioData[0].total : 0
			const text = `${value >= 0 ? '+' : ''}${formatDollars(value, {
				suggestDecimals: true,
			})} (${formatPercent(changeValue)})`
			return {
				value,
				text,
			}
		}
	}, [portfolioData, hoverValue])
	const [showToolTip, setShowToolTip] = useState(false)
	const darkTheme = currentTheme === 'dark'

	const { isWalletConnected } = Connector.useContainer()
	const isL2 = useIsL2()

	const portfolioTimeframe = useAppSelector(selectSelectedPortfolioTimeframe)
	const periodTitle = useMemo(() => {
		return portfolioTimeframe == Period.ONE_DAY
			? 'Today'
			: portfolioTimeframe == Period.ONE_WEEK
			? 'Past Week'
			: portfolioTimeframe == Period.ONE_MONTH
			? 'Past Month'
			: portfolioTimeframe == Period.THREE_MONTHS
			? 'Past 3 Months'
			: portfolioTimeframe == Period.ONE_YEAR
			? 'Past Year'
			: portfolioTimeframe
	}, [portfolioTimeframe])

	return (
		<>
			<DesktopLargeOnlyView>
				<ChartGrid>
					<ChartOverlay>
						<PortfolioTitle>
							{hoverTitle ? hoverTitle : t('dashboard.overview.portfolio-chart.portfolio-value')}
							{!hoverTitle && (
								<IconDivStyle
									onMouseEnter={() => setShowToolTip(true)}
									onMouseLeave={() => setShowToolTip(false)}
								>
									<HelpIcon width={13} height={13} />
								</IconDivStyle>
							)}
							{showToolTip && (
								<OpenMenu>
									<ToolTipHeading>sUSD vs $</ToolTipHeading> Values on DexToro are displayed as $ to
									create a seamless user experience. All perpetuals on DexToro are margined in sUSD.{' '}
								</OpenMenu>
							)}
						</PortfolioTitle>
						<NumericValue fontSize={26} value={hoverValue || total} color="primary">
							{formatDollars(hoverValue || total, { maxDecimals: 2 })}
						</NumericValue>
						<GridBoxRow>
							<NumericValue colored value={changeValue.value ?? ZERO_WEI}>
								{changeValue.text}&nbsp;
							</NumericValue>
							<StyledPeriod>{!hoverValue ? periodTitle : undefined}</StyledPeriod>
						</GridBoxRow>
					</ChartOverlay>

					<GridBox>
						<GridBoxRow>
							<GridInsideDivWithBorder>
								<PortfolioTitle>{t('dashboard.overview.portfolio-chart.upnl')}</PortfolioTitle>
								<NumericValue colored fontSize={20} value={upnl ?? ZERO_WEI}>
									{upnl.gt(ZERO_WEI) ? '+' : ''}
									{formatDollars(upnl, { suggestDecimals: true, maxDecimals: 2 })}
								</NumericValue>
							</GridInsideDivWithBorder>
							<GridInsideDiv>
								<PortfolioTitle>{POSITIONS_TABS[0].label}</PortfolioTitle>
								<NumericValue fontSize={20} value={upnl ?? ZERO_WEI}>
									{POSITIONS_TABS[0].detail}
								</NumericValue>
							</GridInsideDiv>
						</GridBoxRow>
					</GridBox>
					<GridBox>
						<GridBoxRow>
							<GridInsideDivWithBorder onClick={POSITIONS_TABS[0].onClick}>
								<PortfolioTitle>
									{t('dashboard.overview.portfolio-chart.margin-usage')}
								</PortfolioTitle>
								<NumericValue fontSize={20} value={smartAccountBalance ?? ZERO_WEI}>
									{total.cmp(ZERO_WEI)
										? formatPercent(total.sub(smartAccountBalance).div(total))
										: undefined}
								</NumericValue>
							</GridInsideDivWithBorder>
							<GridInsideDiv onClick={POSITIONS_TABS[1].onClick}>
								<PortfolioTitle>
									{t('dashboard.overview.portfolio-chart.free-collateral')}
								</PortfolioTitle>
								<NumericValue fontSize={20} value={smartAccountBalance ?? ZERO_WEI}>
									{formatDollars(smartAccountBalance, { suggestDecimals: true, maxDecimals: 2 })}
								</NumericValue>
							</GridInsideDiv>
						</GridBoxRow>
					</GridBox>
					{isWalletConnected && isL2 && !!total && portfolioData.length >= 2 ? (
						<ChartContainer darkTheme={darkTheme}>
							<TopBar>
								<TimeframeOverlay>
									<Timeframe />
								</TimeframeOverlay>
							</TopBar>
							<PriceChart setHoverValue={setHoverValue} setHoverTitle={setHoverTitle} />
						</ChartContainer>
					) : (
						<ChartContainer darkTheme={darkTheme}>
							<ChartCTA isL2={isL2} />
						</ChartContainer>
					)}
				</ChartGrid>
			</DesktopLargeOnlyView>
			<DesktopSmallOnlyView>
				{!!total && portfolioData.length >= 2 ? (
					<MobileChartGrid>
						<ChartOverlay mobile>
							<FlexDivMobile>
								<Row>
									<PortfolioTitle>
										{hoverTitle
											? hoverTitle
											: t('dashboard.overview.portfolio-chart.portfolio-value')}
										{!hoverTitle && (
											<IconDivStyle
												onMouseEnter={() => setShowToolTip(true)}
												onMouseLeave={() => setShowToolTip(false)}
											>
												<HelpIcon width={13} height={13} />
											</IconDivStyle>
										)}
										{showToolTip && (
											<OpenMenu>
												<ToolTipHeading>sUSD vs $</ToolTipHeading> Values on DexToro are displayed
												as $ to create a seamless user experience. All perpetuals on DexToro are
												margined in sUSD.
											</OpenMenu>
										)}
									</PortfolioTitle>
									<NumericValue fontSize={28} value={hoverValue || total}>
										{formatDollars(hoverValue || total, { maxDecimals: 2 })}
									</NumericValue>
									<FlexDivRowCentered>
										<NumericValue colored value={changeValue.value ?? ZERO_WEI}>
											{changeValue.text}&nbsp;
										</NumericValue>
										<StyledPeriod>{!hoverValue ? periodTitle : undefined}</StyledPeriod>
									</FlexDivRowCentered>
								</Row>
							</FlexDivMobile>
							<div>
								<GridBoxRowMobile>
									<Col>
										<PortfolioTitle>{t('dashboard.overview.portfolio-chart.upnl')}</PortfolioTitle>
										<NumericValue colored fontSize={20} value={upnl ?? ZERO_WEI}>
											{upnl.gt(ZERO_WEI) ? '+' : ''}
											{formatDollars(upnl, { suggestDecimals: true })}
										</NumericValue>
									</Col>
									<Col>
										<PortfolioTitle>{POSITIONS_TABS[0].label}</PortfolioTitle>
										<NumericValue fontSize={20} value={upnl ?? ZERO_WEI}>
											{POSITIONS_TABS[0].detail}
										</NumericValue>
									</Col>
								</GridBoxRowMobile>
								<GridBoxRowMobile>
									<Col onClick={POSITIONS_TABS[0].onClick}>
										<PortfolioTitle>
											{t('dashboard.overview.portfolio-chart.margin-usage')}
										</PortfolioTitle>
										<NumericValue fontSize={20} value={smartAccountBalance ?? ZERO_WEI}>
											{total.cmp(ZERO_WEI)
												? formatPercent(total.sub(smartAccountBalance).div(total))
												: undefined}
										</NumericValue>
									</Col>
									<Col onClick={POSITIONS_TABS[1].onClick}>
										<PortfolioTitle>
											{t('dashboard.overview.portfolio-chart.free-collateral')}
										</PortfolioTitle>
										<NumericValue fontSize={20} value={smartAccountBalance ?? ZERO_WEI}>
											{formatDollars(smartAccountBalance, {
												suggestDecimals: true,
												maxDecimals: 2,
											})}
										</NumericValue>
									</Col>
								</GridBoxRowMobile>
							</div>
						</ChartOverlay>
						<ChartContainer darkTheme={darkTheme}>
							<TopBar>
								<TimeframeOverlay>
									<Timeframe />
								</TimeframeOverlay>
							</TopBar>
							<PriceChart setHoverValue={setHoverValue} setHoverTitle={setHoverTitle} />
						</ChartContainer>
					</MobileChartGrid>
				) : (
					<ChartContainer mobile>
						<ChartCTA isL2={isL2} mobile />
					</ChartContainer>
				)}
			</DesktopSmallOnlyView>
		</>
	)
}

const ChartContainer = styled.div<{ mobile?: boolean; darkTheme?: boolean }>`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	grid-row-end: span 3;
	// border-left: ${(props) => (props.mobile ? null : props.theme.colors.selectedTheme.border)};
	border-top: ${(props) => (props.mobile ? props.theme.colors.selectedTheme.border : null)};
	border-bottom: ${(props) => (props.mobile ? props.theme.colors.selectedTheme.border : null)};
	padding: ${(props) => (props.mobile ? `0px` : ` 0 8px`)};
	background: ${(props) => (props.darkTheme ? `url('${bgImage}')` : 'none')};
	background-position: center;
	background-color: ${(props) => props.theme.colors.selectedTheme.table.fill};
	border-radius: 0 8px 8px 0;
`

const TopBar = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;
	padding: 8px 8px 0 0;
`

const ChartOverlay = styled.div<{ mobile?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 8px;
	padding: ${(props) => (props.mobile ? `0` : `16px`)};
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
`

const GridBox = styled.div`
	display: flex;
	flex-direction: column;
	border-top: ${(props) => props.theme.colors.selectedTheme.border};
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	padding: 0px;
	justify-content: center;
`
const GridBoxRow = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: start;
`

const GridInsideDivWithBorder = styled.div`
	width: 50%;
	cursor: pointer;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	padding: 15px;
`
const GridInsideDiv = styled.div`
	width: 50%;
	cursor: pointer;
	padding: 15px;
`

const TimeframeOverlay = styled.div`
	max-width: 192px;
`

const PortfolioTitle = styled(Body)`
	display: flex;
	position: relative;
	font-size: 13px;
	margin-bottom: 4px;
	gap: 2px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	align-items: center;
`

const MobileChartGrid = styled.div`
	display: grid;
	grid-template-rows: 1fr 5fr;
	width: 100%;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 0;
	height: 516px;
	margin-top: 0;
	border-top: 0;
	border-left: none;
	border-right: none;
`

const GridBoxRowMobile = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	border-top: ${(props) => props.theme.colors.selectedTheme.border};
	&:not(:first-child) {
		border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	}

	span {
		width: 50%;
	}
`

const Row = styled.span`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Col = styled.span`
	display: flex;
	flex-direction: column;
	padding: 20px;
	&:not(:first-child) {
		border-left: ${(props) => props.theme.colors.selectedTheme.border};
	}
`

const ChartGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-template-rows: 2fr fr;
	grid-auto-flow: column;
	width: 100%;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 8px;
	min-height: 320px;
	margin: 32px 0;
	${media.lessThan('xxl')`
		margin: auto;
		border-radius: 0;
		border-top: none;
		border-left: none;
		border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
  `}
`

const CTAContainer = styled(GridDivCenteredRow)<{ isMobile?: boolean }>`
	width: ${(props) => (props.isMobile ? `100%` : `268px`)};
	background-color: ${(props) =>
		props.theme.colors.selectedTheme.newTheme.containers.primary.background};
	border-radius: 8px;
	text-align: center;
	justify-content: center;
	justify-items: center;
	grid-gap: 10px;
	padding: 20px;
	margin: ${(props) => (props.isMobile ? `0px` : `8px 0 0 0`)};
`

const IconDivStyle = styled.div`
	padding: auto;
	cursor: help;
	width: 13px;
	height: 13px;
	margin-left: 2px;
	// margin-top: 2px;
	display: flex;
	align-items: center;
`
const OpenMenu = styled.div`
	z-index: 1001;
	position: absolute;
	background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	top: 20px;
	width: 200px;
	display: grid;
	padding: 13px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 11px;
	backdrop-filter: blur(8px);
	font-size: 12px;
`
const ToolTipHeading = styled.div`
	font-size: 14px;
	font-wight: 300;
	margin-bottom: 2px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
`
const FlexDiv = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
`

const FlexDivMobile = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	gap: 4px;
`

const LegendBody = styled(Body)<{ $color: string }>`
	color: ${(props) => props.$color};
`

const WelcomeHeading = styled(Heading)`
	line-height: 1.2;

	@media (max-width: 480px) {
		width: 150px;
	}
`

const StyledPeriod = styled.span`
	margin-left: 4px;
	font-size: 13px;
	line-height: 1.2;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`
export default PortfolioChart
