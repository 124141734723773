import { ETH_ADDRESS, ETH_COINGECKO_ADDRESS, ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { SynthSymbol } from '@dextoroprotocol/sdk/data'
import { FuturesMarginType } from '@dextoroprotocol/sdk/types'
import { formatDollars, toWei } from '@dextoroprotocol/sdk/utils'
import Wei from '@synthetixio/wei'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ExchangeTokens } from 'types/synths'
import { useConnectModal } from '@rainbow-me/rainbowkit'

import SearchIcon from 'assets/svg/app/search.svg'
import TabFilterButton from 'components/Button/TabFilterButton'
import {
	DesktopLargeOnlyView,
	DesktopOnlyView,
	DesktopSmallOnlyView,
	MobileOrTabletView,
} from 'components/Media'
import SearchBarNewMarket from 'components/SearchBar/SearchBarNewMarket'
import { TabPanel } from 'components/Tab'
import { Body } from 'components/Text'
import Button from 'components/Button'
import { FlexDiv } from 'components/layout/flex'
import FuturesMarketsTable from 'sections/dashboard/FuturesMarketsTable'
import FuturesPositionsTable from 'sections/dashboard/FuturesPositionsTable'
import MobileDashboard from 'sections/dashboard/MobileDashboard'
import PortfolioChart from 'sections/dashboard/PortfolioChart'
import SynthBalancesTable from 'sections/dashboard/SynthBalancesTable'

import Connector from 'containers/Connector'
import { selectBalances } from 'state/balances/selectors'
import { fetchTokenList } from 'state/exchange/actions'
import { selectCrossMarginActivePositions } from 'state/futures/crossMargin/selectors'
import { setFuturesAccountType } from 'state/futures/reducer'
import { setDefaultTab, setOpenModal } from 'state/app/reducer'
import { selectFuturesPortfolio } from 'state/futures/selectors'
import { selectMarkPrices } from 'state/futures/selectors'
import { selectActiveSmartMarginPositionsCount } from 'state/futures/smartMargin/selectors'
import { selectSmartMarginActivePositions } from 'state/futures/smartMargin/selectors'
import { useAppDispatch, useAppSelector, useFetchAction } from 'state/hooks'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import { selectFuturesType } from 'state/futures/common/selectors'
import sdk from 'state/sdk'
import { selectSynthsMap } from 'state/wallet/selectors'
import { getSynthDescription } from 'utils/futures'
import logError from 'utils/logError'
import ROUTES from 'constants/routes'
import useIsL2 from 'hooks/useIsL2'
import { selectCurrentTheme } from 'state/preferences/selectors'
import useLocalStorage from 'hooks/useLocalStorage'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'
import media from 'styles/media'

export enum PositionsTab {
	SMART_MARGIN = 'smart margin',
	SPOT = 'spot',
}

// TODO: Extract initExchangeTokens to SDK/Redux
const Overview: FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const isL2 = useIsL2()
	const { walletAddress } = Connector.useContainer()
	const { openConnectModal } = useConnectModal()
	const accountType = useAppSelector(selectFuturesType)
	const [searchOpen, setSearchOpen] = useState(false)
	const [activeFilter, setActiveFilter] = useState<string>('all')
	const balances = useAppSelector(selectBalances)
	const portfolio = useAppSelector(selectFuturesPortfolio)
	const smartPositionsCount = useAppSelector(selectActiveSmartMarginPositionsCount)

	const [activePositionsTab, setActivePositionsTab] = useState<PositionsTab>(
		PositionsTab.SMART_MARGIN
	)

	const { network } = Connector.useContainer()
	const synthsMap = useAppSelector(selectSynthsMap)

	const { tokenBalances } = useAppSelector(({ balances }) => balances)
	// Only available on Optimism mainnet
	const oneInchEnabled = network.id === 10
	const { isWalletConnected } = Connector.useContainer()
	const [exchangeTokens, setExchangeTokens] = useState<ExchangeTokens>([])
	const [search, setSearch] = useState('')
	const onClearSearch = useCallback(() => {
		setSearchOpen(false)
		return setSearch('')
	}, [setSearch])
	const currentTheme = useAppSelector(selectCurrentTheme)

	useFetchAction(fetchTokenList, { dependencies: [network] })

	useEffect(() => {
		const initExchangeTokens = async () => {
			try {
				const exchangeBalances = oneInchEnabled
					? Object.values(tokenBalances)
							.filter((token) => !synthsMap[token.token.symbol as SynthSymbol])
							.map((value) => ({
								name: value.token.name,
								currencyKey: value.token.symbol,
								balance: toWei(value.balance),
								address:
									value.token.address === ETH_ADDRESS ? ETH_COINGECKO_ADDRESS : value.token.address,
							}))
					: []

				const tokenAddresses = oneInchEnabled
					? [...Object.values(tokenBalances).map((value) => value.token.address.toLowerCase())]
					: []

				const coinGeckoPrices = await sdk.exchange.batchGetCoingeckoPrices(tokenAddresses, true)

				const _exchangeTokens = exchangeBalances.map((exchangeToken) => {
					const { name, currencyKey, balance, address } = exchangeToken

					const price = coinGeckoPrices
						? toWei(coinGeckoPrices[address]?.usd?.toString())
						: ZERO_WEI
					const priceChange = coinGeckoPrices
						? toWei(coinGeckoPrices[address]?.usd_24h_change?.toString()).div(100)
						: ZERO_WEI

					const usdBalance = balance.mul(price)

					return {
						synth: currencyKey as SynthSymbol,
						description: name,
						balance,
						usdBalance,
						price,
						priceChange,
					}
				})

				setExchangeTokens(_exchangeTokens)
			} catch (e) {
				logError(e)
			}
		}

		;(async () => {
			await initExchangeTokens()
		})()
	}, [oneInchEnabled, synthsMap, tokenBalances])

	const POSITIONS_TABS = useMemo(() => {
		const exchangeTokenBalances = exchangeTokens.reduce(
			(initial: Wei, { usdBalance }: { usdBalance: Wei }) => initial.add(usdBalance),
			ZERO_WEI
		)
		return [
			{
				name: PositionsTab.SMART_MARGIN,
				label: t('dashboard.overview.positions-tabs.smart-margin'),
				badge: smartPositionsCount,
				active: activePositionsTab === PositionsTab.SMART_MARGIN,
				detail: formatDollars(portfolio.smartMargin),
				disabled: false,
				onClick: () => {
					setActivePositionsTab(PositionsTab.SMART_MARGIN)
					dispatch(setFuturesAccountType(FuturesMarginType.SMART_MARGIN))
				},
			},
			{
				name: PositionsTab.SPOT,
				label: t('dashboard.overview.positions-tabs.spot'),
				active: activePositionsTab === PositionsTab.SPOT,
				detail: formatDollars(balances.totalUSDBalance.add(exchangeTokenBalances)),
				disabled: false,
				onClick: () => setActivePositionsTab(PositionsTab.SPOT),
			},
		]
	}, [
		t,
		dispatch,
		smartPositionsCount,
		exchangeTokens,
		balances.totalUSDBalance,
		activePositionsTab,
		portfolio.smartMargin,
		setActivePositionsTab,
	])
	const crossMarginPositions = useAppSelector(selectCrossMarginActivePositions)
	const smartMarginPositions = useAppSelector(selectSmartMarginActivePositions)
	const pricesInfo = useAppSelector(selectOffchainPricesInfo)
	const markPrices = useAppSelector(selectMarkPrices)
	let data = useMemo(() => {
		const positions = FuturesMarginType.SMART_MARGIN ? smartMarginPositions : crossMarginPositions
		return positions.map((position) => {
			const description = getSynthDescription(position.market.asset, t)
			const priceInfo = pricesInfo[position.market.asset]
			const marketPrice = markPrices[position.market.marketKey] ?? ZERO_WEI
			return {
				...position,
				description,
				marketPrice,
				priceInfo,
			}
		})
	}, [crossMarginPositions, markPrices, pricesInfo, smartMarginPositions, t])
	const showTable = useMemo(() => (data.length > 0 ? true : false), [data])
	const showExchangeTable = useMemo(
		() => (exchangeTokens.length > 0 ? true : false),
		[exchangeTokens.length]
	)

	const openDepositModal = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('deposit'))
	}

	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	return (
		<>
			<DesktopLargeOnlyView>
				<PortfolioChart POSITIONS_TABS={POSITIONS_TABS} />

				{showTable && (
					<TabPanel name={PositionsTab.SMART_MARGIN} activeTab={activePositionsTab}>
						<FuturesPositionsTable accountType={FuturesMarginType.SMART_MARGIN} />
					</TabPanel>
				)}
				{showExchangeTable && (
					<TabPanel name={PositionsTab.SPOT} activeTab={activePositionsTab}>
						<SynthBalancesTable exchangeTokens={exchangeTokens} />
					</TabPanel>
				)}
				{!showTable && !showExchangeTable && walletAddress && (
					<>
						<HeadingStyleOpenPostion size="large">{'Open Positions'}</HeadingStyleOpenPostion>
						{!isL2 ? (
							<OpenMarketDiv>
								{t('dashboard.overview.futures-positions-table.wrong-network')}
							</OpenMarketDiv>
						) : (
							<OpenMarketDiv>
								{t('futures.market.trade.button.no-open-postion-para')}
								<FlexDiv columnGap="16px">
									<DepositButton onClick={isWalletConnected ? openDepositModal : openConnect}>
										Deposit funds
									</DepositButton>
									<Link href={ROUTES.Markets.Home(accountType)}>
										<StyledButton currentTheme={currentTheme}>Trade</StyledButton>
									</Link>
								</FlexDiv>
							</OpenMarketDiv>
						)}
					</>
				)}
				<FlexDivCentered>
					<div>
						<HeadingStyle size="large">{t('dashboard.overview.markets-tabs.futures')}</HeadingStyle>
						<ParaStyle size="medium" color="secondary">
							{t('dashboard.overview.futures-positions-table.para-table')}
						</ParaStyle>
					</div>
					<ButtonDiv>
						{!searchOpen && (
							<>
								<TabButtonDiv>
									<ButtonFilter
										inline={true}
										title={'All'}
										active={activeFilter === 'all' ? true : false}
										badgeCount={0}
										disabled={true}
										onClick={() => setActiveFilter('all')}
									></ButtonFilter>

									<ButtonFilter
										inline={true}
										title={'Forex'}
										active={activeFilter === 'forex' ? true : false}
										badgeCount={0}
										disabled={true}
										onClick={() => setActiveFilter('forex')}
									></ButtonFilter>

									<ButtonFilter
										inline={true}
										title={'DeFi'}
										active={activeFilter === 'defi' ? true : false}
										badgeCount={0}
										disabled={true}
										onClick={() => setActiveFilter('defi')}
									></ButtonFilter>
								</TabButtonDiv>
								<SearchIconDiv>
									<SearchIconStyle onClick={() => setSearchOpen(true)}>
										<SearchIcon height={15} width={15} />
									</SearchIconStyle>
								</SearchIconDiv>
							</>
						)}
						<SearchBarContainer searchOpen={searchOpen}>
							<SearchBarNewMarket
								autoFocus
								onChange={setSearch}
								value={search}
								border={false}
								placeHolder={'Search markets'}
								onClear={onClearSearch}
							/>
						</SearchBarContainer>
					</ButtonDiv>
				</FlexDivCentered>

				<FuturesMarketsTable search={search} activeFilter={activeFilter} />
			</DesktopLargeOnlyView>
			<DesktopSmallOnlyView>
				<MobileDashboard exchangeTokens={exchangeTokens} isWalletConnected={isWalletConnected} />
				<MobileFutureHeadingDiv>
					<div>
						<HeadingStyle size="large" style={{ fontSize: '24px', lineHeight: '30px' }}>
							{t('dashboard.overview.markets-tabs.futures')}
						</HeadingStyle>
						<ParaStyle
							size="medium"
							color="secondary"
							style={{ fontSize: '16px', lineHeight: '20px' }}
						>
							{t('dashboard.overview.futures-positions-table.para-table')}
						</ParaStyle>
					</div>
				</MobileFutureHeadingDiv>
				<FuturesMarketsTable
					search={search}
					setSearch={setSearch}
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
				/>
			</DesktopSmallOnlyView>
			{firstVisitModal && (
				<FirstSeenWalletConnectionModal
					onDismiss={closeFirstVisitModal}
					onConfirmed={visitModalConfirmed}
				></FirstSeenWalletConnectionModal>
			)}
		</>
	)
}

const SearchBarContainer = styled.div<{ searchOpen: boolean }>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	max-width: none;
	transition: width 0.45s cubic-bezier(0.16, 1, 0.3, 1);
	width: ${(props) => (props.searchOpen ? '360px' : '0')};
	opacity: ${(props) => (props.searchOpen ? '1' : '0')};
`

const MobileFutureHeadingDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 16px;
	max-width: calc(var(1280px) + var(324px));
	gap: 1em;
`

const FlexDivCentered = styled.div`
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	padding: 20px 0px;
	gap: 1em;
	height: 100px;
	padding-left: 18px;
`

const HeadingStyle = styled(Body)`
	display: flex;
	align-items: center;
	-webkit-box-align: center;
	font-size: 20px;
	line-height: 24px;
	gap: 10px;
	white-space: nowrap;
	overflow: auto;
`
const HeadingStyleOpenPostion = styled(Body)`
	font-size: 20px;
	line-height: 24px;
	margin-top: 30px;
	margin-bottom: 20px;
	white-space: nowrap;
	overflow: auto;
	padding-left: 18px;
`
const ParaStyle = styled(Body)`
	font-size: 14px;
	line-height: 18px;
	margin-top: 4px;
`
const ButtonDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	padding: 0 16px;
	height: 60px;
`
const ButtonFilter = styled(TabFilterButton)`
	margin-right: 10px;
`
const TabButtonDiv = styled.div`
	display: flex;
	margin-right: auto;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	padding-right: 10px;
	margin-right: 10px;
	gap: 8px;
`
const TabButtonMobileDiv = styled.div`
	display: flex;
	margin-right: auto;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	padding-right: 10px;
	margin-right: 10px;
	gap: 8px;
`
const SearchIconStyle = styled.div`
	margin-top: 0px;
	cursor: pointer;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`
const SearchIconDiv = styled.div`
	display: flex;
	margin-right: auto;

	height: 100%;
	gap: 20px;
	align-items: center;
	justify-content: end;
`
const OpenMarketDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 12px;
	margin-bottom: 18px;
	padding: 16px 24px;
	min-height: 68px;
	font-size: 15px;
	line-height: 20px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	background: ${(props) => props.theme.colors.selectedTheme.table.fill};
	${media.lessThan('xxl')`
    border-radius: 0;
    border-left: none;
    border-right: none;
  `}
`

const DepositButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	user-select: none;
	white-space: nowrap;
	width: auto;
	font-size: 14px;
	text-transform: none;
	line-height: 18px;
	height: 36px;
	min-height: 36px;
	border-radius: 8px;
	padding: 0px 16px;
`

const StyledButton = styled(Button)<{ currentTheme: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	font-size: 14px;
	line-height: 18px;
	height: 36px;
	min-height: 36px;
	padding: 0px 16px;
	border: none;
	background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
		background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

export default Overview
